import React from "react"
import Layout from "../components/layout"
import Contact from "../components/contact"
import HeadLabel from "../components/head-label"
import PostLink from "../components/post-link"
import Seo from "../components/seo"
import { graphql } from "gatsby"

export default function News({ data }) {
  return (
    <Layout>
      <Seo
        title="News | 株式会社GoodTe | グッテ"
        description="株式会社GoodTeのリリース情報やイベント情報を掲載しています。"
        ogpSiteName="News | 株式会社GoodTeコーポレートサイト"
      />
      <div className="container mx-auto">
        <div className="max-w-4xl mx-auto my-10">
          <HeadLabel label={"ニュース"} />
          {data.allContentfulNews.edges.map(edge => (
            <PostLink key={edge.node.slug} post={edge.node} />
          ))}
        </div>
      </div>
      <Contact />
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulNews(sort: { fields: date, order: DESC }) {
      edges {
        node {
          title
          date(formatString: "YYYY年MM月DD日")
          link
          slug
          createdAt(formatString: "YYYY年MM月DD日")
          metadata {
            tags {
              name
            }
          }
        }
      }
    }
  }
`
